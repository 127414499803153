import Select from 'react-select'
import { SelectFormType} from "../../types/CommonTypes";
import RequiredMark from "./RequiredMark";
import {forwardRef, LegacyRef} from "react";

const AppSelect: React.FC<SelectFormType> = forwardRef((props: SelectFormType, ref: LegacyRef<any>) => {
    const {
        data,
        name,
        label,
        required,
        multiple,
        placeholder,
        selectedValue,
        selectedValueMulti = [],
        text = 'name',
        value = 'uid',
        onChange,
        isLoading = false, ...rest} = props
    return (
        <div>
            {label && <label htmlFor={name} className="block mb-1 text-sm font-bold text-gray-700">{label} {
                required && <RequiredMark/>}</label>}
            <Select
                {...rest}
                name={name}
                options={data}
                isSearchable
                placeholder={placeholder}
                isMulti={multiple}
                defaultValue= {selectedValueMulti}
                defaultInputValue={selectedValue}
                controlShouldRenderValue
                getOptionLabel={(option) => option[text]}
                getOptionValue={(option) =>  option[value]}
                onChange={(option)=> onChange && onChange(option) }
                isLoading={isLoading}
                styles={
                    {
                        placeholder: (provided, state) => ({
                            ...provided,
                            fontWeight: "lighter",
                            fontSize: 14
                        }),
                        control: (provided, state) => ({
                            ...provided,
                            fontWeight: "normal",
                            fontSize: 14,
                            color: "#64748b"
                        }),
                    }
                }
            />
        </div>
    )
})

export default AppSelect
