import {RiEmotionSadLine} from "react-icons/ri"
type Props = {
    message?: string
}

const EmptyState = ({message}: Props) => {
    return(
        <div className="py-5 my-5 flex flex-col justify-center items-center">
            <RiEmotionSadLine className="text-slate-400" size={40} />
            <div className="text-slate-700 mt-2 text-sm">{message ? message : "Aucune donnée trouvée"}</div>
        </div>
    )
}

export default EmptyState
