import {forwardRef, LegacyRef, useState} from "react";
import {BsEye, BsEyeSlash} from "react-icons/bs"
import {InputFormType} from "../../types/CommonTypes";
import RequiredMark from "./RequiredMark";

const AppInput: React.FC<InputFormType> = forwardRef((props: InputFormType, ref: LegacyRef<any>) => {
    const {label, name, type = "text", placeholder, validation, required, error, isTextarea, rows=5, ...rest} = props
    const [usedType, setUsedType] = useState(type)
    const togglePassword = () => {
        if(usedType === "password") setUsedType("text")
        else setUsedType("password")
    }
    return (

        <>
            {label && <label htmlFor={name} className="block mb-1 text-sm font-bold text-gray-700">{label} {
                required && <RequiredMark/>}</label>}
            <div className="relative">
                {!isTextarea ?
                    <>
                        <input required={required} ref={ref} {...rest} autoComplete="off" placeholder={placeholder} id={name} type={usedType}
                               name={name}
                               className={`${error ? "border-red-600 " : "border-slate-300 "}  appearance-none text-sm text-slate-500 placeholder:text-sm placeholder:font-thin placeholder:text-gray-400  w-full rounded border border-b-2  px-3 py-2 shadow-sm focus:border-b-2 focus:outline-none focus:border-b-slate-700 bg-white`}/>
                        {type === "password" &&
                            <span onClick={togglePassword} className="absolute right-3 top-2.5 cursor-pointer">
                                {usedType === "password" ? <BsEye /> : <BsEyeSlash />}
                            </span>
                        }
                    </> :
                    <div>
                        <textarea ref={ref} {...rest} name={name} id={name} rows={rows} placeholder={placeholder}
                                  className={`${error ? "border-red-600 " : "border-slate-300 "}  appearance-none text-sm text-slate-500 placeholder:text-sm placeholder:font-thin placeholder:text-gray-400  w-full rounded border border-b-2  px-3 py-2 shadow-sm focus:border-b-2 focus:outline-none focus:border-b-indigo-400 bg-white`}
                        />
                    </div>
                }
            </div>
        </>
    )
})


export default AppInput
