import {appUrl} from "../constants/endpoints";



const loadCities = async (countryName: string) => {
    return await fetch(`${appUrl}data/cities.json`, {headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}})
        .then(res => res.json())
        .then(data => data.filter((city: any) => city.country === countryName));
}

const exports = {
    loadCities
}

export default  exports
