import {AppButton, AppInput, Header, Spinner} from "../../components";
import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useQuery} from "react-query";
import {https} from "../../services";
import endpoints from "../../constants/endpoints";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import routes from "../../constants/routes";
import InputHelperMessage from "../../components/forms/InputHelperMessage";

const MerchantUpdatePassword = () => {
    const navigate = useNavigate()
    const params = useParams()

    const {isLoading: isMerchantLoading, data: merchantData} = useQuery(["merchant"], async () => {
        return await https.get(endpoints.merchants.url + "/" + params.merchantID)
    }, {staleTime: 500})

    const merchant = merchantData ? merchantData : {}

    const {register, control, handleSubmit, watch, formState: {errors, isSubmitting}} = useForm({
        mode: "onBlur"
    });


    const onSubmit = async (data: any) => {
        if(data?.new !== data?.confirm){
            toast.error("Confirmation du nouveau mot de passe ne correspond pas, verifiez SVP.")
            return
        }
        const res = await https.put(`${endpoints.merchants.password}/${merchant?.uid}`, data)
        if (res.error) {
            toast.error(res.message || "Une erreur est survenue, réessayez SVP.")
            return false
        }
        toast.success("Marchant mis a jour avec succès !")
        navigate(-1)
        return
    }


    return (
        <div>
            <Header title="Modifier Mot de passe"/>
            <div className="p-10">
                {
                    !isMerchantLoading ? <div className="mb-7 pb-4 border-b">
                            <div className="text-3xl font-bold text-slate-800">{merchant?.companyName}</div>
                            <div
                                className="font-bold text-slate-500">{merchant?.owner?.firstname} {merchant?.owner?.lastname} | {merchant?.address}, {merchant?.city}</div>
                        </div>
                        : null
                }

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mx-32">
                        {/*<div className="mb-4">*/}
                        {/*    <AppInput*/}
                        {/*        {...register("old", {required: "Entrez le mot de passe actuel", minLength: {value: 6, message: "Minimum 6 caractères"}})}*/}
                        {/*        label="Mot de passe actuel"*/}
                        {/*        name="old"*/}
                        {/*        type="password"*/}
                        {/*        required*/}
                        {/*    />*/}
                        {/*    {errors && errors["old"] &&*/}
                        {/*        <InputHelperMessage type="error" message={errors["old"]?.message?.toString()}/>}*/}
                        {/*</div>*/}

                        <div className="mb-4">
                            <AppInput
                                {...register("new", {required: "Entrez le nouveau mot de passe", minLength: {value: 6, message: "Minimum 6 caractères"}})}
                                label="Nouveau Mot de passe"
                                name="new"
                                type="password"
                                required
                            />
                            {errors && errors["new"] &&
                                <InputHelperMessage type="error" message={errors["new"]?.message?.toString()}/>}
                        </div>

                        <div className="mb-4">
                            <AppInput
                                {...register("confirm", {required: "Confirmer le nouveau mot de passe", minLength: {value: 6, message: "Minimum 6 caractères"}})}
                                label="Confirmer Nouveau Mot de passe"
                                name="confirm"
                                type="password"
                                required
                            />
                            {errors && errors["confirm"] &&
                                <InputHelperMessage type="error" message={errors["confirm"]?.message?.toString()}/>}
                        </div>
                    </div>

                    <div className="mt-3 text-center">
                        <AppButton
                            category="bloc"
                            className="w-1/2"
                            color="primary"
                            disabled={isSubmitting}
                            type="submit"
                            label={!isSubmitting ? "Valider" : <Spinner/>}/>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default MerchantUpdatePassword
