import React from 'react'

type Props = {
    icon: React.ReactNode,
    title: string
total: string | React.ReactNode
    profit?: string | React.ReactNode
    border?:string
}

export default function TotalCard({icon, total, profit, title, border}: Props) {
  return (
    <div className={`w-full border ${border ? `border-${border}` : 'border-red-300'} py-5 px-6 rounded-lg flex justify-between items-center`}>
        {icon}
        <div className="text-right">
            <div className="text-xs text-gray-600">{title}</div>
            <div className="font-bold text-2xl text-black">
                <span className="text-[9px] font-light text-white py-[2px]  px-[5px] rounded bg-blue-600">Total</span> {total}
            </div>
            {profit ? <div className="font-bold text-xl text-slate-500">
                <span
                    className="text-[9px] font-light text-white py-[2px]  px-[5px] p-1 rounded bg-green-600">Profit</span> {profit}
            </div>: null}
        </div>
    </div>
  )
}
