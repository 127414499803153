import React, {useEffect, useState} from 'react'
import { images } from '../../assets/images'
import {GoHome} from "react-icons/go"
import {IoIosPeople} from "react-icons/io"
import {MdDomain} from "react-icons/md"
import {HiOutlineChartBar} from "react-icons/hi"
import {AiOutlinePoweroff} from "react-icons/ai"
import {useLocation, useNavigate} from 'react-router-dom'
import routes from "../../constants/routes";
import {auth} from "../../services";

export default function SideBar() {
    const navigate = useNavigate()
    const location = useLocation()
    const defaultMenus = [
        {title: "Dashboard", icon: <GoHome size={22}/>, link: routes.home, active: true},
        {title: "Transactions", icon: <HiOutlineChartBar size={22}/>, link: routes.transactions, active: false},
        {title: "Marchants", icon: <IoIosPeople size={22}/>, link: routes.merchants, active: false},
        {title: "Domaines", icon: <MdDomain size={22}/>, link: routes.domains, active: false},
    ]

    useEffect(() => {
        setMenus(prev => {
            return prev.map((item) => {
                if (location.pathname === "/" &&  location.pathname === item.link ) return {...item, active: true}
                else if (location.pathname.includes(item.link.toString()) && item.link !== "/" ) return {...item, active: true}
                else return {...item, active: false}
            })
        })
        return () => {
        }
    }, [location])

    const [menus, setMenus] = useState(defaultMenus)

    const onMenuClick = (index: number) => {
        setMenus(prev => {
            return prev.map((item, i) => {
                if (i === index) return {...item, active: true}
                else return {...item, active: false}
            })
        })
        navigate(menus[index].link)
    }

  return (
    <div className='bg-black w-40 sticky top-0  h-screen'>
        <img src={images.logoLight} alt="Logo" />
        <ul>
            {
                menus.map((menu, index) => (
                    <React.Fragment key={index}>
                        <li
                            onClick={() => onMenuClick(index)}
                            className={`${menu.active ? "bg-slate-900" : ""} flex flex-col justify-center items-center text-white py-5 my-2 cursor-pointer`}>
                            <span>
                                {menu.icon}
                            </span>
                            <span className='text-sm font-medium pt-2'>{menu.title}</span>
                        </li>
                    </React.Fragment>
                ))
            }

        </ul>
        <div className="px-5 absolute bottom-4 w-full">
            <span onClick={() => auth.logout()} className="text-white flex flex-col justify-center items-center py-5 my-2 cursor-pointer">
                <AiOutlinePoweroff size={22}/>
                 <span className='text-sm font-medium pt-2'>Déconnexion</span>
            </span>
        </div>
    </div>
  )
}
