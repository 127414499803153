const Spinner = () => {
    return (
        <div className="flex justify-center items-center">
            <div style={{borderTopColor:"transparent"}} className="w-5 h-5 border-4 border-blue-400 border-solid rounded-full animate-spin"></div>
            <span className="ml-2"> Chargement...</span>
        </div>
    )
}

export default Spinner
