import {toast} from "react-toastify";
import {images} from "../../assets/images";
import { AppForm } from "../../components";
import {auth} from "../../services";


const Login = () => {
    const onSubmit = async (data:any) => {
        const res = await auth.login(data)
        if(res)  window.location.href = "/"
        else toast.error("Email ou mot de passe incorrect !")
    }

    return (
        <div className="flex flex-row justify-center items-center w-screen h-screen bg-slate-100">
            <div className="basis-2/6 bg-black w-full  h-full flex justify-center items-center">
                <img src={images.logoLight} alt="" className="w-1/2"/>
            </div>
            <div className="basis-4/6  w-full h-full flex justify-center items-center">
                <div className="w-1/2">
                    <div className="font-extrabold text-gray-900 text-3xl">Connectez-vous</div>
                    <p className="text-gray-500 text-sm">Entrez vos identifiants pour accéder au portail</p>

                    <div className="pt-4">
                    <AppForm onSubmit={onSubmit} fields={[
                        {
                            label: "Email",
                            name: "email",
                            type: "email",
                            validation: {required: "Entrez votre adresse email"}
                        },
                        {
                            label: "Mot de passe",
                            name: "password",
                            type: "password",
                            validation: {required: "Entrez votre mot de passe"}
                        },
                    ]} />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Login
