import {DateFormType} from "../../types/CommonTypes";
import React, {forwardRef, LegacyRef} from "react";
import RequiredMark from "./RequiredMark";
import DatePicker from "react-date-picker";


const AppDatePicker: React.FC<DateFormType> = forwardRef((props: DateFormType, ref: LegacyRef<any>) => {
    const {
        name,
        label,
        required,
        value,
        placeholder,
        defaultValue,
        onChange, ...rest} = props
    return (
        <div>
            {label && <label htmlFor={name} className="block mb-1 text-sm font-bold text-gray-700">{label} {
                required && <RequiredMark/>}</label>}
            <DatePicker className="border-slate-300 appearance-none text-sm text-slate-500 placeholder:text-sm placeholder:font-thin placeholder:text-gray-400  w-full rounded border border-b-2  px-3 py-2 shadow-sm focus:border-b-2 focus:outline-none focus:border-b-slate-700" onChange={onChange} value={value} />
        </div>
    )
})


export default AppDatePicker
