import {AppButton, AppInput, AppSelect, Header, Spinner, PhoneInput} from "../../components";
import React, {useEffect, useState} from "react";
import {useForm, Controller} from "react-hook-form";
import InputHelperMessage from "../../components/forms/InputHelperMessage";
import endpoints from "../../constants/endpoints";
import countiesData from "../../constants/countries.json"
import {useQuery} from "react-query";
import {https} from "../../services";
import {CommonFunctions} from "../../utils";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import routes from "../../constants/routes";


const FormTitle = ({title}: {title: string}) => {
    return(
        <div className="border-b border-slate-200 pb-2 text-2xl font-bold text-black">
            {title}
        </div>
    )
}

const MerchantForm = () => {
    const navigate = useNavigate()
    const {register, control, handleSubmit, watch, formState: {errors, isSubmitting}} = useForm({
        mode: "onBlur"});
    const [selectedCountry, setSelectedCountry] = useState('Democratic Republic of the Congo')
    const [selectedPhone, setSelectedPhone] = useState<string|number>("")
    const [cities, setCities] = useState([])

    const {isLoading: isDomainsLoading, data: domainsData} = useQuery(["domains"], async () => {
        return await https.get(endpoints.domains.url)
    }, {staleTime: 120000})
    const domains: [] = domainsData || []

    const onSubmit = async (data:any) => {
        if(!data?.country){
            toast.error("Selectionnez un pays SVP")
            return
        }
        if(!data?.businessDomain?.id){
            toast.error("Selectionnez un domaine SVP")
            return
        }
        if(!data?.city?.name){
            toast.error("Selectionnez une ville SVP")
            return
        }
        let body = data
        body.owner = {
            phoneNumber: data.owner_phoneNumber,
            email: data.owner_email,
            firstname: data.owner_firstname,
            lastname: data.owner_lastname
        }
        body.country =  data.country && data.country.country_name ? data.country.country_name :   data.country
        body.city = data.city.name
        body.paymentChannel = {
            phoneNumber: data.payment_phoneNumber,
            merchantPays: data.merchantPays.value,
            feesProportion: parseFloat(data.feesProportion)
        }
        body.password = data?.password
        delete body.owner_phoneNumber
        delete body.owner_email
        delete body.owner_firstname
        delete body.owner_lastname
        delete body.payment_phoneNumber
        delete body.merchantPays
        delete body.feesProportion
        const res = await https.post(endpoints.merchants.url, body)

        if(res.error) {
            toast.error(res.message ||  "Une erreur est survenue, réessayez SVP.")
            return false
        }
        toast.success("Marchant ajouté avec succès !")
        navigate(routes.merchants)
        return
    }

    const getCities = async (countryName: string) => {
        setCities(await CommonFunctions.loadCities(countryName))
    }

    useEffect(() => {
        const subscription = watch(async (value, {name, type}) => {
            if (name === "country")  onSelectCountry(value.country)
            //if (name === "phoneNumber")  setSelectedPhone(value.phoneNumber)
        });
        return () => subscription.unsubscribe();
    }, [watch])

    useEffect(() => {
        getCities(selectedCountry)
    }, [])

    const onSelectCountry = (country: { country_name: string, [key: string]: string }) => {
        getCities(country.country_name)
        setSelectedCountry(country.country_name)
    }

    return(
        <div>
            <Header title="Créer un marchant" />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="p-10">
                    <FormTitle title="Business" />
                    <div className="mt-5 mb-10">
                        <div className="mb-[15px] grid grid-cols-4 gap-4">
                            <div className="col-span-2">
                                <AppInput
                                    {...register("companyName", {required: "Entrez le nom de la compagnie"})}
                                    label="Nom de la compagnie"
                                    name="companyName"
                                    required
                                />
                                {errors && errors["companyName"] &&
                                    <InputHelperMessage type="error" message={errors["companyName"]?.message?.toString()}/>}
                            </div>
                            <div className="col-span-2">
                                <Controller
                                    control={control}
                                    name="businessDomain"
                                    render={({field}) => (
                                        <AppSelect
                                            {...field}
                                           required
                                           label="Domaine d'affaires"
                                           name="businessDomain"
                                           text="name"
                                           value="uid"
                                           data={domains}
                                           isLoading={isDomainsLoading}
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="mb-[15px] grid grid-cols-4 gap-4">
                            <div className="col-span-2">
                                <AppInput
                                    {...register("nationalId", {required: "Entrez le ID NAT de la compagnie"})}
                                    label="ID NAT"
                                    name="nationalId"
                                    required
                                />
                                {errors && errors["nationalId"] &&
                                    <InputHelperMessage type="error" message={errors["nationalId"]?.message?.toString()}/>}
                            </div>
                            <div className="col-span-2">
                                <AppInput
                                    {...register("officialNumber", {required: "Entrez le RCCM de la compagnie"})}
                                    label="RCCM/Patente"
                                    name="officialNumber"
                                    required
                                />
                                {errors && errors["officialNumber"] &&
                                    <InputHelperMessage type="error" message={errors["officialNumber"]?.message?.toString()}/>}
                            </div>
                        </div>

                        <div className="mb-[15px] grid grid-cols-3 gap-4">
                            <div className="col-span-1">
                                <Controller
                                    control={control}
                                    rules={{ required: "Entrez ce champs" }}
                                    name="phoneNumber"
                                    render={({field}) => (
                                        <PhoneInput
                                            {...field}
                                            name="phoneNumber"
                                            label="Tél."
                                            required
                                        />
                                    )}
                                />
                                {errors && errors["phoneNumber"] &&
                                    <InputHelperMessage type="error" message={errors["phoneNumber"]?.message?.toString()}/>}
                            </div>
                            <div className="col-span-1">
                                <AppInput
                                    {...register("email", {required: "Entrez l'adresse email de la compagnie"})}
                                    label="Email"
                                    name="email"
                                    required
                                    type="email"
                                />
                                {errors && errors["email"] &&
                                    <InputHelperMessage type="error" message={errors["email"]?.message?.toString()}/>}
                            </div>
                            <div className="col-span-1">
                                <AppInput
                                    {...register("website")}
                                    label="Site web"
                                    name="website"
                                />
                            </div>
                        </div>

                        <div className="mb-[15px] grid grid-cols-3 gap-4">
                            <div className="col-span-1">
                                <Controller
                                    control={control}
                                    name="country"
                                    render={({field}) => (
                                        <AppSelect
                                            {...field}
                                            name="country"
                                            label="Pays"
                                            required
                                            value="country_name"
                                            text="country_name"
                                            data={countiesData}
                                            selectedValue='Democratic Republic of the Congo'
                                        />
                                    )}
                                />
                                {errors && errors["country"] &&
                                    <InputHelperMessage type="error" message={errors["country"]?.message?.toString()}/>}
                            </div>
                            <div className="col-span-1">
                                <Controller
                                    control={control}
                                    name="city"
                                    render={({field}) => (
                                        <AppSelect
                                            {...field}
                                            name="city"
                                            label="Ville"
                                            text="name"
                                            value="name"
                                            required
                                            data={cities}/>
                                    )}
                                />
                                {errors && errors["city"] &&
                                    <InputHelperMessage type="error" message={errors["city"]?.message?.toString()}/>}
                            </div>
                            <div className="col-span-1">
                                <AppInput
                                    {...register("address", {required: "Entrez l'adresse de la companie"})}
                                    name="address"
                                    label={"Adresse"}
                                    required
                                />
                                {errors && errors["address"] &&
                                    <InputHelperMessage type="error" message={errors["address"]?.message?.toString()}/>}
                            </div>
                        </div>
                    </div>

                    <FormTitle title="Représentant" />
                    <div className="mt-5 mb-10">
                        <div className="mb-[15px] grid grid-cols-4 gap-4">
                            <div className="col-span-2">
                                <AppInput
                                    {...register("owner_firstname", {required: "Entrez le prénom du représentant"})}
                                    label="Prénom"
                                    name="owner_firstname"
                                    required
                                />
                                {errors && errors["owner_firstname"] &&
                                    <InputHelperMessage type="error" message={errors["owner_firstname"]?.message?.toString()}/>}
                            </div>
                            <div className="col-span-2">
                                <AppInput
                                    {...register("owner_lastname", {required: "Entrez le nom du représentant"})}
                                    label="Nom"
                                    name="owner_lastname"
                                    required
                                />
                                {errors && errors["owner_lastname"] &&
                                    <InputHelperMessage type="error" message={errors["owner_lastname"]?.message?.toString()}/>}
                            </div>
                        </div>
                        <div className="mb-[15px] grid grid-cols-4 gap-4">
                            <div className="col-span-2">
                                <Controller
                                    control={control}
                                    rules={{ required: "Entrez ce champs" }}
                                    name="owner_phoneNumber"
                                    render={({field}) => (
                                        <PhoneInput
                                            {...field}
                                            name="owner_phoneNumber"
                                            label="Tél."
                                            required
                                        />
                                    )}
                                />
                                {errors && errors["owner_phoneNumber"] &&
                                    <InputHelperMessage type="error" message={errors["owner_phoneNumber"]?.message?.toString()}/>}
                            </div>
                            <div className="col-span-2">
                                <AppInput
                                    {...register("owner_email", {required: "Entrez l'adresse email du représentant"})}
                                    label="Email"
                                    name="owner_email"
                                    required
                                    type="email"
                                />
                                {errors && errors["owner_email"] &&
                                    <InputHelperMessage type="error" message={errors["owner_email"]?.message?.toString()}/>}
                            </div>
                        </div>
                        <div className="mb-[15px] grid grid-cols-4 gap-4">
                            <div className="col-span-4">
                                <AppInput
                                    {...register("password", {required: "Entrez le mot de passe", minLength: {value: 6, message: "Minimum 6 caractères"}})}
                                    label="Mot de passe"
                                    name="password"
                                    type="password"
                                    required
                                />
                                {errors && errors["password"] &&
                                    <InputHelperMessage type="error" message={errors["password"]?.message?.toString()}/>}
                            </div>
                        </div>
                    </div>

                    <FormTitle title="Paiement" />
                    <div className="mt-5 mb-10">
                        <div className="mb-[15px] grid grid-cols-3 gap-4">
                            <div className="col-span-1">
                                <Controller
                                    control={control}
                                    rules={{ required: "Entrez ce champs" }}
                                    name="payment_phoneNumber"
                                    render={({field}) => (
                                        <PhoneInput
                                            {...field}
                                            value={selectedPhone}
                                            name="payment_phoneNumber"
                                            label="Tél."
                                            required
                                        />
                                    )}
                                />
                                {errors && errors["payment_phoneNumber"] &&
                                    <InputHelperMessage type="error" message={errors["payment_phoneNumber"]?.message?.toString()}/>}
                            </div>
                            <div className="col-span-1">
                                <AppInput
                                    {...register("feesProportion", {required: "Entrez le frais applicable", pattern: {value: /[0-9.]/, message: "Entrez un montant valide"}})}
                                    label="Frais applicable"
                                    name="feesProportion"
                                    required
                                />
                                {errors && errors["feesProportion"] &&
                                    <InputHelperMessage type="error" message={errors["feesProportion"]?.message?.toString()}/>}
                            </div>
                            <div className="col-span-1">
                                <Controller
                                    control={control}
                                    rules={{ required: "Entrez ce champs" }}
                                    name="merchantPays"
                                    render={({field}) => (
                                        <AppSelect
                                            {...field}
                                            required
                                            label="Supporté par le marchant ?"
                                            name="merchantPays"
                                            text="name"
                                            value="value"
                                            data={[{name: "OUI", value: true}, {name: "NON", value: false}]}
                                        />
                                    )}
                                />
                                {errors && errors["merchantPays"] &&
                                    <InputHelperMessage type="error" message={errors["merchantPays"]?.message?.toString()}/>}

                            </div>
                        </div>
                    </div>

                    <div className="mt-8 text-center">
                        <AppButton
                            category="bloc"
                            className="w-1/2"
                            color="primary"
                            disabled={isSubmitting}
                            type="submit"
                            label={!isSubmitting ? "Enregistrer le marchant" : <Spinner/>}/>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default MerchantForm
