import React, {useEffect, useState} from 'react'
import {AppButton, AppDatePicker, EmptyState, Header, LottieFile, TotalCard} from '../../components'
import DataTable from 'react-data-table-component';
import {useNavigate, useParams} from "react-router-dom";
import DatePicker from 'react-date-picker';
import {useQuery} from "react-query";
import {https} from "../../services";
import endpoints from "../../constants/endpoints";
import loadingBar from "../../assets/lotties/loading-bar.json";
import routes from "../../constants/routes";
import {Formats} from "../../utils";
import moment from "moment/moment";
import {IoLogoUsd} from "react-icons/io";

const TransactionExpandedComponent = ({ data }: {data: any}) => {
    return(
        <ul>
            <li>
                <span>Date</span>
            </li>
        </ul>
    )
};

export default function MerchantTransaction() {
    const navigate = useNavigate()
    const params = useParams()
    const [fromDate, setFromDate] = useState<Date|string>(moment().format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState<Date|string>(moment().format('YYYY-MM-DD'));
    const [bridge, setBridge] = useState<string>("all");
    const [totalCount, setTotalCount] = useState<number>(0);
    const [isRefetching, setIsRefetching] = useState<boolean>(false);
    const {isLoading, data, refetch} = useQuery(["payments"], async () => {
        setIsRefetching(true)
        const res =  await https.get(`${endpoints.merchants.url}/${params.merchantID}/payments?bridge=${bridge}&from=${fromDate}&to=${toDate}`)
        setIsRefetching(false)
        setTotalCount(res.payments.length)
        return res
    }, {staleTime: 1000, enabled: isRefetching})

    const transactions = data ?  data : []

    const columns = [
        {
            name: 'MoMo',
            selector: (row: {[key:string]:any}) => row.paymentCompanyName,
            sortable: true,
            maxWidth: "5px"
        },
        {
            name: 'Réf.',
            selector: (row: {[key:string]:any}) => row.arakaTransactionId,
            sortable: true,
            maxWidth: "5px"
        },
        {
            name: 'Date',
            selector: (row: {[key:string]:any}) => Formats.date(row.createdAt),
            sortable: true,
        },
        {
            name: 'Téléphone',
            selector: (row: {[key:string]:any}) => row.clientPhoneNumber,
            sortable: true,
        },
        {
            name: 'Montant Initié',
            selector: (row: {[key:string]:any}) => `${Formats.number(row.initialAmount)} ${row.currency}`,
            sortable: true,
            right: true
        },
        {
            name: 'Montant Total',
            selector: (row: {[key:string]:any}) => `${Formats.number(row.totalAmount)} ${row.currency}`,
            sortable: true,
            right: true
        },
        {
            name: 'Status',
            selector: (row: {[key:string]:any}) => row.statusDescription,
            sortable: true,
            right: true
        },

    ];

    useEffect(() => {
        refetch()
    },  [bridge, fromDate, toDate])

    const {isLoading: isMerchantLoading, data: merchantData} = useQuery(["merchant"], async () => {
        return await https.get(endpoints.merchants.url+"/"+params.merchantID)
    }, {staleTime: 500})

    const merchant = merchantData ?  merchantData : {}


    return (
        <div>
            <Header title={`Transactions (${totalCount})`} />
            <div  className="p-10">
                {!isLoading ? <div className="mb-7 pb-4 border-b">
                    <div className="text-3xl font-bold text-slate-800">{merchant?.companyName}</div>
                    <div className="font-bold text-slate-500">{merchant?.owner?.firstname}  {merchant?.owner?.lastname} | {merchant?.address}, {merchant?.city}</div>
                </div>: null}


                <div className="pb-4 font-bold">Filtres</div>
                <div className="grid grid-cols-4 gap-4 pb-5 border-b">
                    <AppDatePicker name="fromDate" onChange={(value:Date) => setFromDate(moment(value).format('YYYY-MM-DD'))} value={new Date(fromDate)} />
                    <AppDatePicker name="toDate" onChange={(value:Date) => setToDate(moment(value).format('YYYY-MM-DD'))} value={new Date(toDate)} />
                    <div className="col-span-2">
                        <AppButton onClick={() => setBridge("all")} category="bloc" size="xs" label="Tous" color={bridge === 'all' ? "default" : "default-outline" } />
                        <AppButton onClick={() => setBridge("MPESA")} className="ml-2" category="bloc" size="xs" label="MPesa" color={bridge === 'MPESA' ? "default" : "default-outline" } />
                        <AppButton onClick={() => setBridge("ORANGE")} className="ml-2" category="bloc" size="xs" label="Orange Money" color={bridge === 'ORANGE' ? "default" : "default-outline" } />
                        <AppButton onClick={() => setBridge("AIRTEL")} className="ml-2" category="bloc" size="xs" label="Airtel Money" color={bridge === 'AIRTEL' ? "default" : "default-outline" } />
                        <AppButton onClick={() => setBridge("AFRICELL")} className="ml-2" category="bloc" size="xs" label="AfriMoney" color={bridge === 'AFRICELL' ? "default" : "default-outline" } />
                    </div>
                </div>

                <div className="grid grid-cols-2 gap-4 my-4">
                    <div>
                        <TotalCard
                            icon={<IoLogoUsd className="text-blue-600" size={50}/>}
                            title="Total Transactions USD"
                            total={isLoading ? <LottieFile width={60} height={10} animationData={loadingBar} /> : Formats.number(transactions.usdBalance?.sum)}
                            profit={isLoading ? <LottieFile width={60} height={10} animationData={loadingBar} /> :Formats.number(transactions.usdBalance?.gain || 0)}
                            border="sky-900"
                        />
                    </div>
                    <div>
                        <TotalCard
                            icon={<div className="text-cyan-800 font-bold text-3xl">CDF</div>}
                            title="Total Transactions CDF"
                            total={isLoading ? <LottieFile width={60} height={10} animationData={loadingBar} /> : Formats.number(transactions.cdfBalance?.sum)}
                            profit={isLoading ? <LottieFile width={60} height={10} animationData={loadingBar} /> :Formats.number(transactions.cdfBalance?.gain || 0)}
                            border="sky-900"
                        />
                    </div>
                </div>

                <DataTable
                    persistTableHead
                    progressPending={isLoading || isRefetching}
                    progressComponent={<LottieFile animationData={loadingBar} />}
                    columns={columns}
                    data={transactions.payments}
                    highlightOnHover
                    pointerOnHover
                    noDataComponent={<EmptyState />}
                    onRowClicked={(row, event) => {
                        navigate(routes.transactions+"/"+row.transactionUid)
                    }}
                    //expandableRows
                    expandableRowDisabled={row => row.disabled}
                    expandableRowsComponent={TransactionExpandedComponent}
                />
            </div>
        </div>
    )
}
