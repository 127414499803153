import React from 'react';
import { ToastContainer } from "react-toastify";
import {Route, Routes, useLocation} from "react-router-dom";
import {LoggedRoute, ProtectedRoute} from "./helpers";
import {
    DomainForm,
    DomainsList,
    Home,
    Login,
    MerchantDetail,
    MerchantForm,
    MerchantList, MerchantTransaction, MerchantUpdatePassword,
    TransactionDetail,
    TransactionList
} from "./screens";
import './App.css';
import { SideBar } from './components';
import routes from './constants/routes';

import "react-toastify/dist/ReactToastify.css";
import {UserContextProvider} from "./contexts";

function App() {
    const location = useLocation();
    const unAuthRoutes = ["/login"]
    return (
        <div className="transition duration-200 ease-in-out">
            <UserContextProvider>
            {location && unAuthRoutes.includes(location.pathname) ? (
                <Routes>
                    <Route element={<LoggedRoute/>}>
                        <Route path={routes.login} element={<Login/>}/>
                    </Route>
                </Routes>
            ) : (
                <div className="flex">
                    <SideBar />
                    <div className='w-full overflow-y-hidden pb-10'>
                    <Routes>
                        <Route element={<ProtectedRoute/>}>
                            <Route path={routes.home} element={<Home/>}/>
                            <Route path={routes.transactions} element={<TransactionList/>}/>
                            <Route path={`${routes.transactions}/:id`} element={<TransactionDetail/>}/>

                            <Route path={routes.merchants} element={<MerchantList/>}/>
                            <Route path={`${routes.merchants}/:id`} element={<MerchantDetail/>}/>
                            <Route path={routes.addMerchant} element={<MerchantForm/>}/>
                            <Route path={`${routes.merchantPayments}/:merchantID`} element={<MerchantTransaction/>}/>
                            <Route path={`${routes.merchantPassword}/:merchantID`} element={<MerchantUpdatePassword/>}/>

                            <Route path={routes.domains} element={<DomainsList/>}/>
                            <Route path={routes.addDomains} element={<DomainForm />}/>
                        </Route>
                    </Routes>
                    </div>

                </div>
            )}
            </UserContextProvider>
            <ToastContainer />
        </div>
    );
}

export default App;
