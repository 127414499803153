const routes = {
    home: "/",
    login: "/login",
    transactions: "/transactions",
    merchants: "/merchants",
    merchantPayments: "/merchant/payments",
    merchantPassword: "/merchant/password",
    addMerchant: "/add-merchant",
    domains: "/domains",
    addDomains: "/add-domain",
}

export default routes
