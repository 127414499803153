import {useQuery} from "react-query";
import {FaMoneyBillWave} from 'react-icons/fa'
import {IoIosPeople, IoLogoUsd} from "react-icons/io"
import moment from 'moment';
import {EmptyState, Header, LottieFile, TotalCard} from "../../components";
import loadingBar from "../../assets/lotties/loading-bar.json"
import endpoints from "../../constants/endpoints";
import {https} from "../../services";
import {Formats} from "../../utils";
import routes from "../../constants/routes";
import DataTable from "react-data-table-component";
import React from "react";
import {useNavigate} from "react-router-dom";

const Title = ({title}: {title: string}) => {
    return(
        <div className="pb-2 text-xl font-bold text-slate-500">
            {title}
        </div>
    )
}

export default function Home() {
    const navigate = useNavigate()
    const todayDate = moment().format('YYYY-MM-DD');
    const {isLoading, data} = useQuery(["merchants"], async () => {
        return await https.get(endpoints.merchants.url)
    }, {staleTime: 120000})
    const merchants = data || []

    const {isLoading: isTransactionLoading, data: transactionData} = useQuery(["payments"], async () => {
        return await https.get(`${endpoints.payments.url}?bridge=all&from=${todayDate}&to=${todayDate}`)
    }, {staleTime: 120000})
    const transactions = transactionData || []

    const columns = [
        {
            name: 'MoMo',
            selector: (row: {[key:string]:any}) => row.paymentCompanyName,
            sortable: true,
            maxWidth: "5px"
        },
        {
            name: 'Réf.',
            selector: (row: {[key:string]:any}) => row.arakaTransactionId,
            sortable: true,
            maxWidth: "5px"
        },
        {
            name: 'Téléphone',
            selector: (row: {[key:string]:any}) => row.clientPhoneNumber,
            sortable: true,
        },
        {
            name: 'Montant',
            selector: (row: {[key:string]:any}) => `${Formats.number(row.initialAmount)} ${row.currency}`,
            sortable: true,
            right: true
        },
        {
            name: 'Status',
            selector: (row: {[key:string]:any}) => row.statusDescription,
            sortable: true,
            right: true
        },

    ];

    return (
        <div>
            <Header title="Dashboard" />
            <div className="p-10">
                <div className="grid grid-cols-2 gap-4">
                    <div className="col-span-1 border border-slate-300 rounded p-3">
                        <Title title="Récentes transactions du jour"/>
                        <div className="flex flex-col justify-between items-center">
                            <DataTable
                                persistTableHead
                                fixedHeader
                                progressPending={isTransactionLoading}
                                progressComponent={<LottieFile animationData={loadingBar} />}
                                columns={columns}
                                data={transactions && transactions.payments ? transactions.payments.slice(0, 6) : []}
                                highlightOnHover
                                pointerOnHover
                                noDataComponent={<EmptyState />}
                                onRowClicked={(row, event) => {
                                    navigate(routes.transactions+"/"+row.transactionUid)
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-span-1">
                        <TotalCard
                            icon={<IoLogoUsd className="text-blue-600" size={50}/>}
                            title="Transactions du jour USD"
                            total={isTransactionLoading ? <LottieFile width={60} height={10} animationData={loadingBar} /> : Formats.number(transactions.usdBalance?.sum || 0)}
                            profit={isTransactionLoading ? <LottieFile width={60} height={10} animationData={loadingBar} /> : Formats.number(transactions.usdBalance?.gain || 0)}
                            border="sky-900"
                        />
                        <div className="my-4"></div>
                        <TotalCard
                            icon={<div className="text-cyan-800 font-bold text-3xl">CDF</div>}
                            title="Transactions du jour CDF"
                            total={isTransactionLoading ? <LottieFile width={60} height={10} animationData={loadingBar} /> :Formats.number(transactions.cdfBalance?.sum || 0)}
                            profit={isTransactionLoading ? <LottieFile width={60} height={10} animationData={loadingBar} /> :Formats.number(transactions.cdfBalance?.gain || 0)}
                            border="teal-700"
                        />
                        <div className="my-4"></div>
                        <TotalCard
                            icon={<IoIosPeople className="text-red-900" size={50}/>}
                            title="Total Marchants"
                            total={isLoading ? <LottieFile width={60} height={10} animationData={loadingBar} /> : merchants.length}
                            border="red-900"
                        />
                    </div>
                </div>

            </div>
        </div>
    )
}
