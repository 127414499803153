import http from "./httpService"
import {AppStorage} from "../utils";
import endpoints from "../constants/endpoints";

const login: Function = async (posts: {}) => {
    const res = await http.post(endpoints.auth.login, posts)
    if(res.data.error)  return false
    AppStorage.setItem('_hmlToken', res.data.jwt)
    return true
}

const logout: Function = async () => {
    AppStorage.clearAll()
    window.location.href = "/login"
    return true
}

const getCurrentUser: () => {} | null = () => {
    const user = AppStorage.getItem("user")
    return user ? JSON.parse(user) : null
}

const updateCurrentUser: (user: {}) => void = (user: {}) => AppStorage.setItem("user", JSON.stringify(user))

const getAuthToken: () => string | null  = () =>  AppStorage.getItem("_hmlToken")

const exps = {
    login,
    logout,
    updateCurrentUser,
    getCurrentUser,
    getAuthToken,
}

export default exps
