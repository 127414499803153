import http from "./httpService";
import {auth} from "./index";

const token = auth.getAuthToken()
const headers = { "Authorization": "Bearer " + token }

const get = async (url: string) => {
    const {data} = await http.get(url, {headers})
    return data
}

const put = async (url:string, data?:any) => {
    const {data: res} = await http.put(url, data, {headers})
    return res
}

const patch = async (url:string, data?:any) => {
    const {data: res} = await http.patch(url, data, {headers})
    return res
}

const post = async (url:string, data?:any) => {
    const {data: res} = await http.post(url, data, {headers})
    return res
}

const exps = {
    get,
    put,
    patch,
    post
}

export default exps
