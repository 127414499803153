import React, {useEffect} from 'react'
import {AiOutlinePlus} from "react-icons/ai"
import {AppButton, AppInput, EmptyState, Header, LottieFile} from '../../components'
import DataTable from 'react-data-table-component';
import {useQuery} from "react-query";
import {https} from "../../services";
import endpoints from "../../constants/endpoints";
import {useNavigate} from "react-router-dom";
import routes from "../../constants/routes";
import loadingBar from "../../assets/lotties/loading-bar.json"
import {useForm} from "react-hook-form";


export default function DomainsList() {
    const {register, watch} = useForm({
        mode: "all", defaultValues: {
            searchKey: ""}
    });
    const navigate = useNavigate()
    const {isLoading, data} = useQuery(["domains"], async () => {
        return await https.get(endpoints.domains.url)
    }, {staleTime: 1000})
    const domains: {[key:string]:any}[] = data || []

    const columns = [
        {
            name: 'Nom',
            selector: (row: {[key:string]:any}) => row.name,
            sortable: true,
        },
        {
            name: 'Description',
            selector: (row: {[key:string]:any}) => row.comments,
            sortable: true,
        },

    ];


    useEffect(() => {
        const subscription = watch(async (value, {name, type}) => {
            console.log(value)
        });
        return () => subscription.unsubscribe();
    }, [watch])

    return (
        <div>
            <Header title="Domaines d'activité" />
            <div  className="p-10">
                <div className="grid grid-cols-4 mb-5">
                    <div className="col-span-1">
                        {/*<AppInput*/}
                        {/*    {...register("searchKey")}*/}
                        {/*    label="Recherche"*/}
                        {/*    name="searchKey"*/}
                        {/*/>*/}
                    </div>
                    <div className="col-span-3  text-right">
                        <AppButton
                            label="Créer un domaine"
                            size="sm"
                            category="bloc"
                            icon={<AiOutlinePlus size={15} />}
                            onClick={() => navigate(routes.addDomains)}
                        />
                    </div>
                </div>
                <DataTable
                    persistTableHead
                    fixedHeader
                    progressPending={isLoading}
                    progressComponent={<LottieFile animationData={loadingBar} />}
                    columns={columns}
                    data={domains}
                    highlightOnHover
                    pointerOnHover
                    noDataComponent={<EmptyState />}
                />
            </div>
        </div>
    )
}
