import React from "react";
import {ButtonType} from "../../types/CommonTypes";

const AppButton: React.FC<ButtonType> = (props: ButtonType) => {
    const {
        label,
        onClick,
        type = "button",
        disabled = false,
        size = "md",
        category = "rounded",
        color = "default",
        className = "",
        icon
    } = props

    let classes = className + " focus:outline-none font-medium transition-all duration-500 ease-in-out "

    if(size === "xs") classes += " px-4 py-2 text-xs "
    else if(size === "sm") classes += "  px-4 py-2 text-sm "
    else if(size === "lg") classes += " px-6 py-5 text-2xl "
    else classes += " px-5 py-3 text-md "

    if(category === "bloc") classes += " rounded-md "
    else classes += " rounded-full "

    if(color === "primary") classes += "  bg-black text-white hover:bg-primary-hover "
    else if(color === "primary-outline") classes += " border text-primary-base border-primary-base hover:bg-primary-base hover:text-white"
    else if(color === "secondary") classes += ""
    else if(color === "secondary-outline") classes += ""
    else if(color === "success-outline") classes += " border border-green-600 text-green-600 text-white hover:bg-green-600 hover:text-white "
    else if(color === "success") classes += " bg-green-600 text-white hover:bg-green-800 "
    else if(color === "warning") classes += ""
    else if(color === "warning-outline") classes += ""
    else if(color === "danger") classes += " text-white bg-red-600 hover:bg-red-700 "
    else if(color === "danger-outline") classes += " border text-red-500 border-red-600 hover:bg-red-600 hover:text-white "
    else if(color === "default-outline") classes += " bg-white border text-gray-700 border-gray-300 hover:bg-gray-300 hover:text-gray-700 "
    else classes += " bg-gray-700 text-white hover:bg-gray-900 hover:bg-gray-700 border border-gray-700"

    return (
        <button onClick={onClick ? () => onClick() : ()=>{} } type={type} disabled={disabled} className={classes}>
            <span className=" flex justify-center items-center">
                {icon && <span className="pr-3">{icon}</span>} {label}
            </span>
        </button>
    )
}

export default AppButton
