import React from "react";

type InputMsgType = {
    message?: string ,
    type?: string
}

type renderType = {
    message: string,
}

const RenderDefault: React.FC<renderType> = ({message}: renderType) => {
    return(
        <div className="font-medium text-xs mt-1">{message}</div>
    )
}

const RenderSuccess: React.FC<renderType> = ({message}: renderType) => {
    return(
        <div className="font-medium text-xs mt-1 text-green-700">{message}</div>
    )
}

const RenderError: React.FC<renderType> = ({message}: renderType) => {
    return(
        <div className="font-medium text-xs text-sm mt-1 text-red-500">{message}</div>
    )
}

const InputHelperMessage: React.FC<InputMsgType> = (props: InputMsgType) => {
    const {message, type="default"} = props
    if(type ===  'error') return <RenderError message={message?message : " "} />
    if(type ===  'success') return <RenderSuccess message={message?message : " "} />
    return <RenderDefault message={message?message : " "} />
}

export default InputHelperMessage