const { REACT_APP_APP_URL } = process.env

export const app = {
    url: REACT_APP_APP_URL
}
export const appUrl = REACT_APP_APP_URL

const domains = {
    url: "/business-domains",
}

const merchants = {
    url: "/merchants",
    password: "merchants/password"
}

const auth = {
    login: "/admins/auth/login",
}

const payments = {
    url: "/payments",
}

const exps =  {
    app,
    domains,
    merchants,
    payments,
    auth
}

export default exps
