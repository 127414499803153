import React, {useEffect} from 'react'
import {AiOutlineUserAdd} from "react-icons/ai"
import {AppButton, AppInput, EmptyState, Header, LottieFile} from '../../components'
import DataTable from 'react-data-table-component';
import {useQuery} from "react-query";
import {https} from "../../services";
import endpoints from "../../constants/endpoints";
import {useNavigate} from "react-router-dom";
import routes from "../../constants/routes";
import loadingBar from "../../assets/lotties/loading-bar.json"
import {useForm} from "react-hook-form";


export default function MerchantList() {
  const {register, watch} = useForm({
    mode: "all", defaultValues: {
      searchKey: ""}
  });
  const navigate = useNavigate()
  const {isLoading, data} = useQuery(["merchants"], async () => {
    return await https.get(endpoints.merchants.url)
  }, {staleTime: 1000})
  const merchants: {[key:string]:any}[] = data || []

  const columns = [
    {
      name: 'Compagnie',
      selector: (row: {[key:string]:any}) => row.companyName,
      sortable: true,
    },
    {
      name: 'Propriétaire',
      selector: (row: {[key:string]:any}) => `${row.owner.firstname} ${row.owner.lastname}`,
      sortable: true,
    },
    {
      name: 'Adresse',
      selector: (row: {[key:string]:any}) => row.address,
      sortable: true,
    },
    {
      name: 'Ville',
      selector: (row: {[key:string]:any}) => row.city,
      sortable: true,
    },
    {
      name: 'Pays',
      selector: (row: {[key:string]:any}) => row.country,
      sortable: true,
    },
    {
      name: 'Tél. paiement',
      selector: (row: {[key:string]:any}) => row.paymentChannel.phoneNumber,
      sortable: true,
    },

  ];


  useEffect(() => {
    const subscription = watch(async (value, {name, type}) => {
      console.log(value)
    });
    return () => subscription.unsubscribe();
  }, [watch])

  return (
      <div>
        <Header title="Marchants" />
        <div  className="p-10">
          <div className="grid grid-cols-4 mb-5">
            <div className="col-span-1">
              {/*<AppInput*/}
              {/*    {...register("searchKey")}*/}
              {/*    label="Recherche"*/}
              {/*    name="searchKey"*/}
              {/*/>*/}
            </div>
            <div className="col-span-3  text-right">
              <AppButton
                  label="Créer un marchant"
                  size="sm"
                  category="bloc"
                  icon={<AiOutlineUserAdd size={15} />}
                  onClick={() => navigate(routes.addMerchant)}
              />
            </div>
          </div>
          <DataTable
              persistTableHead
              fixedHeader
              progressPending={isLoading}
              progressComponent={<LottieFile animationData={loadingBar} />}
              columns={columns}
              data={merchants}
              highlightOnHover
              pointerOnHover
              noDataComponent={<EmptyState />}
              onRowClicked={(row, event) => {
                navigate(routes.merchants+"/"+row.uid)
              }}
          />
        </div>
      </div>
  )
}
