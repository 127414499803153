import React, {createContext, useState} from "react";
import {auth} from "../services";

type UserCtxType = {
    token: string | null
    user: {} | null
}
type Props = {
    currentUser: UserCtxType
    setCurrentUser: Function
}

export const UserContext = createContext<Props>({
    currentUser: {
        token: null,
        user:  null,
    },
    setCurrentUser: () => {}
})

const getCurrentUser = () => {
    const token = auth.getAuthToken()
    const user = null
    return {token, user}
}

const UserContextProvider: React.FC<any> = (props: any) => {
    const [currentUser, setCurrentUser] = useState<UserCtxType>(getCurrentUser())

    const value: Props = {currentUser, setCurrentUser}

    return (
        <UserContext.Provider value={value}>
            {props.children}
        </UserContext.Provider>
    );
}

export default UserContextProvider;
