import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from "react-router-dom";
import {AppButton, Header, LottieFile} from "../../components";
import {https} from "../../services";
import endpoints from "../../constants/endpoints";
import loadingBar from "../../assets/lotties/loading-bar.json";
import routes from "../../constants/routes";
import {BsList, BsLock} from "react-icons/bs";

const Title = ({title}: {title: string}) => {
  return(
      <div className="pb-2 text-xl font-bold text-slate-500">
        {title}
      </div>
  )
}

const Line = ({title, desc}: {title: string, desc: string}) => {
  return(
      <div className="grid grid-cols-3 mt-3 border-b border-slate-100 pb-2">
        <div className="col-span-1 text-sm">{title}</div>
        <div className="col-span-2 text-sm font-bold">{desc}</div>
      </div>
  )
}

export default function MerchantDetail() {
  const params = useParams();
  const navigate = useNavigate()
    const [merchant, setMerchant] = useState<{[key:string]: any}|null>(null)
    const [isLoading, setIsLoading] = useState(false)

    const getMerchant = async () => {
        setIsLoading(true)
        const res =  await https.get(endpoints.merchants.url+"/"+params.id)
        setMerchant(res);
        setIsLoading(false)
    };

    useEffect(() => {
        getMerchant()
    }, [])

  return (
    <div>
      <Header title="Detail Marchant" />
      <div  className="p-10">
        <div className="grid grid-cols-5 gap-4">
            <div className="col-span-4 px-6">
            {isLoading ?<LottieFile animationData={loadingBar} />  : <div>
                {merchant ?
                    (<div>
                        <div className="font-bold text-2xl mb-7 flex flex-1 justify-between">
                            {merchant.companyName}
                            <div>
                                <AppButton icon={<BsList />} onClick={() => navigate(`${routes.merchantPayments}/${params.id}`)} size="sm" color="success" category="bloc" label="Liste de transactions" />
                                <AppButton icon={<BsLock />} onClick={() => navigate(`${routes.merchantPassword}/${params.id}`)} size="sm" color="danger-outline" category="bloc" className="ml-3" label="Modifier Mot de passe" />
                            </div>
                        </div>
                        <Title title="Business"/>
                        <div className="mt-5 mb-10">
                            <Line title="Compagnie" desc={merchant.companyName}/>
                            <Line title="Domaine d'activité" desc={merchant.businessDomain.name}/>
                            <Line title="Téléphone" desc={merchant.phoneNumber}/>
                            <Line title="Email" desc={merchant.email}/>
                            <Line title="Adresse" desc={merchant.address}/>
                            <Line title="Ville" desc={merchant.city}/>
                            <Line title="Pays" desc={merchant.country}/>
                            <Line title="ID NAT" desc={merchant.nationalId}/>
                            <Line title="RCCM/Patente" desc={merchant.officialNumber}/>
                            <Line title="Site web" desc={merchant.website}/>
                        </div>

                        <Title title="Représentant"/>
                        <div className="mt-5 mb-10">
                            <Line title="Prénom" desc={merchant.owner.firstname}/>
                            <Line title="Nom" desc={merchant.owner.lastname}/>
                            <Line title="Téléphone" desc={merchant.owner.phoneNumber}/>
                            <Line title="Email" desc={merchant.owner.email}/>
                        </div>

                        <Title title="Paiement"/>
                        <div className="mt-5">
                            <Line title="Téléphone" desc={merchant.paymentChannel.phoneNumber}/>
                            <Line title="Frais Applicable" desc={`${merchant.paymentChannel.feesProportion} %`}/>
                            <Line title="Supporté par le marchant ?"
                                  desc={`${merchant.paymentChannel.merchantPays ? 'OUI' : 'NON'}`}/>
                        </div>
                    </div>)
                    : null}
            </div>}
            </div>
          <div  className="col-span-1">
            {/*<AppButton icon={<FiEdit3 />} category="bloc" label="Modifier" className="w-full" />*/}
            {/*<AppButton icon={<BsLock />} category="bloc" color="danger" label="Bloquer" className="w-full mt-4" />*/}
          </div>
        </div>
      </div>
    </div>
  )
}
