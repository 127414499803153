import {AppButton, AppInput, AppSelect, Header, Spinner, PhoneInput} from "../../components";
import React from "react";
import {useForm} from "react-hook-form";
import InputHelperMessage from "../../components/forms/InputHelperMessage";
import endpoints from "../../constants/endpoints";
import {https} from "../../services";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import routes from "../../constants/routes";



const DomainForm = () => {
    const navigate = useNavigate()
    const {register, handleSubmit, formState: {errors, isSubmitting}} = useForm({
        mode: "onBlur"});


    const onSubmit = async (data:any) => {
        const res = await https.post(endpoints.domains.url, data)
        if(res.error) {
            toast.error(res.message ||  "Une erreur est survenue, réessayez SVP.")
            return false
        }
        toast.success("Domaine ajouté avec succès !")
        navigate(routes.domains)
        return
    }


    return(
        <div>
            <Header title="Créer un domaine" />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="p-10">
                    <div className="mt-5 mb-10">
                        <div className="mb-[15px] grid grid-cols-4 gap-4">
                            <div className="col-span-4">
                                <AppInput
                                    {...register("name", {required: "Entrez le nom du domaine"})}
                                    label="Nom du domaine"
                                    name="name"
                                    required
                                />
                                {errors && errors["name"] &&
                                    <InputHelperMessage type="error" message={errors["name"]?.message?.toString()}/>}
                            </div>
                        </div>

                        <div className="mb-[15px] grid grid-cols-4 gap-4">
                            <div className="col-span-4">
                                <AppInput
                                    {...register("comments")}
                                    label="Description"
                                    name="comments"
                                    isTextarea
                                />
                                {errors && errors["comments"] &&
                                    <InputHelperMessage type="error" message={errors["comments"]?.message?.toString()}/>}
                            </div>
                        </div>
                    </div>

                    <div className="mt-8 text-center">
                        <AppButton
                            category="bloc"
                            className="w-1/2"
                            color="primary"
                            disabled={isSubmitting}
                            type="submit"
                            label={!isSubmitting ? "Enregistrer le domaine" : <Spinner/>}/>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default DomainForm
