import React from 'react'

type Props = {
    title: string
}

export default function Header({title}: Props) {
  return (
    <div className='shadow-lg py-4 px-4 w-full sticky top-0 flex'>
        <span className='font-semibold text-2xl'>{title}</span>
    </div>
  )
}