const formatDate = ($date: number[]) => {
    let  strDate: Date |  null = null
    let  options = {}
    if($date.length > 3){
        strDate = new Date($date[0], $date[1]-1, $date[2], $date[3], $date[4], $date[5])
        options = {  year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false }; //weekday: 'long', month: 'short'
    }else {
        strDate = new Date($date[0], $date[1]-1, $date[2])
        options = {  year: 'numeric', month: '2-digit', day: '2-digit' };
    }
    return strDate.toLocaleDateString("fr-FR", options)
}

const numberFormats = (num:number, decimals = 2, dec_point = ".", thousands_sep = " ")  => {
    // Strip all characters but numerical ones.
    const number = (num + '').replace(/[^0-9+\-Ee.]/g, '');
    let n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s : string | string[]= '',
        toFixedFix = function (n:number, prec: number) {
            let k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

const exps = {
    date: formatDate,
    number: numberFormats
}
export default exps
