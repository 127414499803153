import axios, {AxiosInstance, AxiosRequestHeaders} from "axios"
import axiosRetry from "axios-retry";
import {AppStorage} from "../utils";
import {toast} from "react-toastify";

const { REACT_APP_BASE_URL } = process.env


let headers: AxiosRequestHeaders = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*"
}

const axiosInstance: AxiosInstance = axios.create({
    baseURL: REACT_APP_BASE_URL,
    headers,
    timeout: 60000,
    withCredentials: true,
})

axiosRetry(axiosInstance, {
    retries: 3,
    shouldResetTimeout: true,
});

axiosInstance.interceptors.request.use(
    config =>  config,
    error =>  Promise.reject('Erreur --> ' + error)
)

axiosInstance.interceptors.response.use(
    res => res,
    err => {
        if(!err.response) {
            toast.warning("Problème de connexion, réessayez SVP.")
            console.log("No response", err)
            return false
        }
        if(err.response.status === 401){
            console.log("Please, session expired login again.", "error")
            AppStorage.clearAll()
            window.location.href = "/login"
            return false
        }
        if(err.response.status !== 200) {
            return {
                data: {
                    error: true,
                    status: err.response.status,
                    message: err.response.data.code,
                    data: err.response.data
                }
            }
        }
        return err.response
    }
)

const methods = {
    get: axiosInstance.get,
    post: axiosInstance.post,
    put: axiosInstance.put,
    patch: axiosInstance.patch,
    delete: axiosInstance.delete
}

export default methods
