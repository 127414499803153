import React, {useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from "react-router-dom";
import {useQuery} from "react-query";
import {https} from "../../services";
import endpoints from "../../constants/endpoints";
import {AppButton, EmptyState, Header, LottieFile} from "../../components";
import loadingBar from "../../assets/lotties/loading-bar.json";
import {Formats} from "../../utils";
import routes from "../../constants/routes";
import TransactionStatus from "../../components/common/TransactionStatus";

const Title = ({title}: {title: string}) => {
  return(
      <div className="pb-2 text-xl font-bold text-slate-500">
        {title}
      </div>
  )
}

const Line = ({title, desc}: {title: string, desc: string | React.ReactNode}) => {
  return(
      <div className="grid grid-cols-3 mt-3 border-b border-slate-100 pb-2">
        <div className="col-span-1 text-sm">{title}</div>
        <div className="col-span-2 text-sm font-bold">{desc}</div>
      </div>
  )
}

export default function TransactionDetail() {
  const params = useParams();
    const navigate = useNavigate()
    const [payment, setPayment] = useState<{[key:string]: any}|null>(null)
    const [isLoading, setIsLoading] = useState(false)

    const getTransaction = async () => {
        setIsLoading(true)
        const res =  await https.get(endpoints.payments.url+"/"+params.id)
        setPayment(res);
        setIsLoading(false)
    };

    useEffect(() => {
        getTransaction()
    }, [])

  return (
    <div>
      <Header title="Detail de la transaction" />
      <div  className="p-10 grid grid-cols-5 gap-4">
        <div className="col-span-4 px-6">
          {isLoading ?<LottieFile animationData={loadingBar} />  :
            <div>
              {payment ? ( <div>
                  <Title title="Transaction"/>
                  <div className="mt-5 mb-10">
                    <Line title="Réf." desc={payment.arakaTransactionId}/>
                    <Line title="MoMo" desc={payment.paymentCompanyName}/>
                    <Line title="Téléphone" desc={payment.clientPhoneNumber}/>
                    <Line title="Date" desc={Formats.date(payment.createdAt)}/>
                    <Line title="Montant initié" desc={`${Formats.number(payment.initialAmount)} ${payment.currency}`}/>
                    <Line title="Montant Total" desc={`${Formats.number(payment.totalAmount)} ${payment.currency}`}/>
                    <Line title="Status" desc={<TransactionStatus title={payment.statusDescription} />}/>
                  </div>

                  <div className="flex flex-row justify-between">
                      <Title title="Marchant"/>
                      <AppButton category="bloc" size="xs" onClick={() => navigate(routes.merchants+"/"+payment.merchant.uid)} label="Voir le marchant" />
                  </div>

                  <div className="mt-5 mb-10">
                      <Line title="Compagnie" desc={payment.merchant.companyName}/>
                      <Line title="Email" desc={payment.merchant.email}/>
                      <Line title="Téléphone" desc={payment.merchant.phoneNumber}/>
                      <Line title="Adresse" desc={payment.merchant.address}/>
                      <Line title="Ville" desc={payment.merchant.city}/>
                      <Line title="Pays" desc={payment.merchant.country}/>
                      <Line title="Représentant" desc={`${payment.merchant.owner.firstname} ${payment.merchant.owner.lastname}`}/>
                  </div>
              </div>) : <div className="text-center">

                  <a href={routes.transactions+"/"+params.id}>
                      <AppButton onClick={() => navigate(routes.transactions+"/"+params.id)} size="xs" label="Actualiser" />
                  </a>
                  <EmptyState />

              </div>}
            </div>
          }
        </div>
      </div>
    </div>
  )
}
