import {useForm, SubmitHandler, UnpackNestedValue} from "react-hook-form";
import {InputFormType} from "../../types/CommonTypes";
import AppInput from "./AppInput";
import InputHelperMessage from "./InputHelperMessage";
import {AppButton, Spinner} from "../index";
import React from "react";


type AppFormType = {
    children?: React.ReactNode;
    fields: InputFormType[],
    onSubmit: (data: UnpackNestedValue<any>) => void,
    hasSubmitButton?: boolean,
    buttonLabel?: string
}

const AppForm: React.FC<AppFormType> = (props: AppFormType) => {
    const {
        handleSubmit,
        register,
        formState: {errors, isSubmitting},
    } = useForm();
    const {fields, onSubmit, hasSubmitButton = true, buttonLabel="Valider"} = props

    const validate: SubmitHandler<{}> = data => {
        return new Promise<void>((resolve) => {
            resolve(onSubmit(data))
            return
          })
    };


    return (
        <form onSubmit={handleSubmit(validate)}>
            <div className="grid grid-cols-4 gap-x-3">
                {
                    fields.map((item, index) => {
                        const itemCols = item.cols ? item.cols === 2 ? "col-span-2" : "col-span-4" : "col-span-4"
                        return (
                            <div key={index} className={"mb-[15px] " + itemCols}>
                                <AppInput
                                    {...register(item.name, {...item.validation})}
                                    type={item.type}
                                    name={item.name}
                                    label={item.label}
                                    placeholder={item.placeholder}
                                />
                                {errors && errors[item.name]  ?
                                    <InputHelperMessage type="error" message={errors[item.name]?.message?.toString()}/>:  null}
                            </div>
                        )
                    })
                }
            </div>

            {props.children}

            {hasSubmitButton && (<div className="mt-8">
                <AppButton className="w-full" color="primary" disabled={isSubmitting} type="submit"
                           label={!isSubmitting ? buttonLabel : <Spinner/>}/>
            </div>)}
        </form>
    )
}

export default AppForm
