import {forwardRef, LegacyRef} from "react";
import PhoneInput from 'react-phone-input-2'
import '../../styles/phone-input.css'
import {InputFormType} from "../../types/CommonTypes";
import RequiredMark from "./RequiredMark";

const AppPhoneInput: React.FC<InputFormType> = forwardRef((props: InputFormType, ref: LegacyRef<any>) => {
    const {name, value, required, label, ...rest} = props
    return(
        <div>
            {label && <label htmlFor={name} className="block mb-1 text-sm font-bold text-gray-700">{label} {
                required && <RequiredMark/>}</label>}
            <PhoneInput
                {...rest}
                value={value}
                country={'cd'}
                inputClass="appearance-none text-sm text-slate-500 placeholder:text-sm placeholder:font-thin placeholder:text-gray-400  w-full rounded border border-b-2 border-slate-300 px-3 py-2 shadow-sm focus:border-b-2 focus:outline-none focus:border-b-slate-700 bg-white"
                inputProps={{ name, required }}
                enableSearch
            />
        </div>
    )
})

export default AppPhoneInput
